.m-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 0 rem(16);
  justify-content: space-between;
  z-index: 10;
  background: rgba($black, 0.3);
  @include mh(10px);
  // box-shadow: 0 0 rem(16) $bg;
  &,
  &-block {
    height: rem(62);
    display: flex;
    align-items: center;
  }
  &-block {
    justify-content: flex-end;
  }
  &-block &-menu-icon {
    margin-right: rem(-8);
  }
  &-logo {
    width: rem(132);
    a {
      text-indent: -9999px;
      height: rem(32);
      display: block;
      background: url($res + 'swap/logo.svg') center left / contain no-repeat;
    }
  }
  & > main {
    display: flex;
    align-items: center;
    gap: rem(20);

    svg {
      width: rem(32);
    }
  }
  &-menu {
    position: fixed;
    top: 0;
    left: 100vw;
    height: 100vh;
    width: 100vw;
    z-index: 12;
    padding: rem(0) rem(24) rem(24);
    overflow-y: auto;
    background: $bg;
    opacity: 0;
    @include dz();
    &.active {
      left: 0;
      opacity: 1;
    }
    &-icon {
      width: rem(40);
      height: rem(40);
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: rem(16);
      i {
        position: relative;
        &,
        &:before,
        &:after {
          display: block;
          width: rem(16);
          height: rem(1.5);
          background: $white;
          opacity: 1;
          @include dz();
        }
        &:before,
        &:after {
          content: '';
          position: absolute;
        }
        &:before {
          top: rem(-5);
        }
        &:after {
          top: rem(5);
        }
      }
      &.active {
        i {
          @include xz(45);
          &:before {
            top: rem(0);
            opacity: 0;
          }
          &:after {
            top: rem(0);
            @include xz(90);
          }
        }
      }
    }

    & > button {
      height: rem(40);
    }

    .c-dropdown-label {
      height: rem(40);
      padding: 0 rem(12);
      font-size: rem(15);
    }
  }
  &-nav {
    & > a {
      height: rem(48);
      display: block;
      display: flex;
      align-items: center;
      padding: 0 rem(12);
      color: $text2;
      font-size: rem(15);
      &.active {
        color: #a99eda;
        font-weight: bold;
      }
      img {
        width: rem(24);
        height: rem(24);
        margin-right: rem(8);
      }
    }
  }
}
